import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { useKeycloak } from '@react-keycloak/web';
import {
  apiKit,
  apiMergeImage,
  apiFooter,
  apiFinancialStatus,
} from 'services/data';
import { Modal, Loading } from 'components';
import { BiArrowBack } from 'react-icons/bi';

import { CardDetail, NothingFound } from 'components';
import { Button } from 'styles';
import { Kit as KitOjbect } from 'interfaces/Kit/Kit.interface';
import * as S from './styles';

interface User {
  id: string;
  footer?: {
    id: string;
    path: string;
  };
}

const KitView: React.FC = () => {
  const [isLoading, seIstLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Carregando...');
  const [modal, setModal] = useState(false);
  const [kit, setKit] = useState<KitOjbect>({} as KitOjbect);
  const [kitUrl, setKitUrl] = useState('');
  const [footer, setFooter] = useState<User>({} as User);
  const [financingStatus, setFinancingStatus] = useState(false);

  const { keycloak, initialized } = useKeycloak();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    seIstLoading(true);

    async function fetchData() {
      if (initialized) {
        try {
          const requestFinancialStatus = await apiFinancialStatus.getStatus();
          setFinancingStatus(requestFinancialStatus.data.debito);

          if (requestFinancialStatus.data.debito === true) {
            addToast({
              type: 'info',
              title: 'Restrição financeira',
              description: 'Entre em contato com a central da Rede',
            });
          }

          try {
            /* Request footer by user */
            const reqFooter = await apiFooter.show(
              keycloak.idTokenParsed?.sub || '',
            );

            if (reqFooter.status !== 404) {
              setFooter({
                id: keycloak.idTokenParsed?.sub!,
                footer: { id: reqFooter.data.id, path: reqFooter.data.path },
              });
            }

            try {
              /* Request kit already prepare */
              const response = await apiKit.show(id);
              setKit(response.data);

              if (keycloak.tokenParsed?.sub! && id) {
                try {
                  const responseUrl = await apiMergeImage.show(
                    keycloak.tokenParsed?.sub!,
                    id,
                  );
                  setKitUrl(responseUrl.data.url);
                } catch (err) {
                  addToast({
                    type: 'error',
                    title: 'Falha',
                    description: (err as Error).message,
                  });
                }
              }
            } catch (err) {
              addToast({
                type: 'error',
                title: 'Erro ao buscar Kit',
                description: (err as Error).message,
              });
            }
          } catch (err) {
            addToast({
              type: 'error',
              title: 'Erro ao buscar rodapé',
              description: 'Entre em contato com a equipe de Marketing',
            });
          }
        } catch (erro) {
          throw new Error("Fatal error!!!");
        }
        seIstLoading(false);
      }
    }
    fetchData();

    // eslint-disable-next-line
  }, []);

  const handlePrepareDownload = useCallback(async () => {
    seIstLoading(true);
    try {
      if (id) {
        if (keycloak.tokenParsed?.sub!) {
          setLoadingMessage(
            'Aguarde enquanto preparamos seu KIT personalizado',
          );
          const response = await apiMergeImage.merge(id);
          setKitUrl(response.data.url);
        } else {
          setModal(true);
        }
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ATENÇÃO',
        description: (err as Error).message,
      });
      seIstLoading(false);
      setLoadingMessage('Carregando...');
    }

    seIstLoading(false);
    //eslint-disable-next-line
  }, [id, addToast]);

  const handleDownload = useCallback(async () => {
    window.location.href = kitUrl;
  }, [kitUrl]);

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
          <S.LoadingTitle>{loadingMessage}</S.LoadingTitle>
        </>
      ) : typeof kit.files === 'undefined' ? (
        <NothingFound />
      ) : (
        <S.Container>
          <S.ButtonContainer>
            <BiArrowBack
              size={30}
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer' }}
            />

            <div id="buttons_wrapper">
              <Button
                size="download"
                onClick={handlePrepareDownload}
                bgColor={
                  footer.footer?.path && financingStatus === false
                    ? 'download'
                    : 'disabled'
                }
                disabled={
                  footer.footer?.path && financingStatus === false
                    ? false
                    : true
                }
              >
                PREPARAR KIT
              </Button>

              <Button
                size="download"
                onClick={handleDownload}
                bgColor={
                  kitUrl.length > 0 &&
                  keycloak.tokenParsed?.sub! &&
                  financingStatus === false
                    ? 'download'
                    : 'disabled'
                }
                disabled={
                  kitUrl.length > 0 &&
                  keycloak.tokenParsed?.sub! &&
                  financingStatus === false
                    ? false
                    : true
                }
              >
                REALIZAR DOWNLOAD
              </Button>
            </div>
          </S.ButtonContainer>
          <Modal showModal={modal} closeModal={setModal} />
          <S.CardContainer>
            {kit.files.length > 0 && (
              <>
                {kit.files.map(file => (
                  <CardDetail key={file.file} image={file.path} />
                ))}
              </>
            )}
          </S.CardContainer>
        </S.Container>
      )}
    </>
  );
};

export default KitView;
