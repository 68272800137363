import React from 'react';
import { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

const Login = () => {
  const { keycloak, initialized } = useKeycloak();

  const location = useLocation<{ [key: string]: unknown }>();
  const currentLocationState = location.state || {
    from: { pathname: '/' },
  };

  const login = useCallback(async () => {
    if (initialized) {
      await keycloak?.login();
    }
  }, [keycloak, initialized]);

  if (!!keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from as string} />;
  } else {
    login();
    return <></>;
  }
};

export default Login;
