export const localization = {
  body: {
    emptyDataSourceMessage: 'Nenhuma informação',
    editRow: { deleteText: 'Tem certeza que deseja deletar este registro?' },
  },
  header: {
    actions: 'Ações',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'Registros',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página',
  },
  toolbar: {
    exportName: 'Exportar para CSV',
    exportTitle: 'Exportar',
    searchPlaceholder: 'Procurar',
    searchTooltip: 'Procurar',
  },
};

export const options = {
  pageSize: 5,
  pageSizeOptions: [5, 10, 20, 50, 100, 200, 300, 500],
  actionsColumnIndex: -1,
};

export const MAX_FONT_SIZE = { fontSize: 14 };
