import styled from 'styled-components';

export const Container = styled.footer`
  margin: 0;
  background-image: linear-gradient(to right,#ffb245,#e73439, #b31b62);
  height: 100px;
`;

export const ItensContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  svg {
    cursor: pointer;
  }
  @media (max-width: 850px) {
    justify-content: flex-start;
    margin-left: 15px;
  }

`;
