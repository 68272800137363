import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10%;
  cursor: pointer;
  transition-duration: 1s;

  &:hover {
    bottom: 12%;
    right: 15px;
  }
`;

export const WhatsappIcon = styled.img`
  height: 80px;
  width: 80px;
`;
