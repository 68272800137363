import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import {
  Main,
  AllKits,
  AllBoutiques,
  UpdateUser,
  UpdateLicensee,
  Kit,
  Dashboard,
  AdminKit,
  AdminBoutique,
  CreateKit,
  CreateBoutique,
  Licensee,
  InitialLogin,
} from '../pages';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={InitialLogin} />
      <PrivateRoute exact path="/" component={Main} />
      <PrivateRoute exact path="/allKits" component={AllKits} />
      <PrivateRoute exact path="/allBoutiques" component={AllBoutiques} />
      <PrivateRoute exact path="/kit/:id" component={Kit} />
      <PrivateRoute path="/updateUser/:userId" component={UpdateUser} />
      <PrivateRoute path="/updateLicensee/:userId" component={UpdateLicensee} />
      <PrivateRoute path="/updateUser" component={UpdateUser} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/kit" component={AdminKit} />
      <PrivateRoute path="/boutique" component={AdminBoutique} />
      <PrivateRoute path="/kit-create" component={CreateKit} />
      <PrivateRoute path="/boutique-create" component={CreateBoutique} />
      <PrivateRoute path="/licensee" component={Licensee} />
    </Switch>
  );
};

export default Routes;
