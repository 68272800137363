import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
import AppProvider from './hooks';
import GlobalStyle from 'styles/GlobalStyle';
import { UrlProvider } from 'hooks/url';

const App = () => {
  return (
    <Router>
      <AppProvider>
        <UrlProvider>
          <Routes />
        </UrlProvider>
      </AppProvider>
      <GlobalStyle />
    </Router>
  );
};

export default App;
