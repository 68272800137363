import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { useToast } from 'hooks/toast';
import { Loading } from 'components';
import { localization, options } from 'utils';
import { Boutique } from 'interfaces/Boutique/Boutique.interface';
import apiBoutique from 'services/data/Boutique';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Button } from 'styles';
import * as S from './styles';

const BoutiqueScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [boutiques, setBoutiques] = useState<Boutique[]>([]);

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    try {
      apiBoutique.index(true).then(response => setBoutiques(response.data));
    } catch (err) {
      const { status, message } = err.response.data;
      addToast({
        type: status,
        title: 'ATENÇÃO',
        description: message,
      });
    }

    setIsLoading(false);
  }, [update, addToast]);

  const handleDelete = useCallback(
    async ({ id }) => {
      setIsLoading(true);
      try {
        if (window.confirm('Tem certeza que deseja deletar este registro?')) {
          await apiBoutique.delete(id);
        }
      } catch (err) {
        const { status, message } = err.response.data;
        addToast({
          type: status,
          title: 'ATENÇÃO',
          description: message,
        });
      }
      setIsLoading(false);
      setUpdate(old => !old);
    },
    [addToast],
  );

  const handleImage = useCallback((image: string) => {
    window.open(image, '_blank');
  }, []);

  const columns = [
    { title: 'BOUTIQUE', field: 'boutique' },
    {
      title: 'DATA REFERÊNCIA',
      field: 'referringMonth',
      render: (rowData: Boutique) => (
        <>
          {format(parseISO(rowData.referringMonth.toString()), 'dd/MM/yyyy', {
            locale: ptBR,
          })}
        </>
      ),
    },
    {
      title: 'CAPA',
      field: 'cover',
      render: (rowData: Boutique) => (
        <span
          onClick={() => handleImage(rowData.cover)}
          style={{ cursor: 'pointer' }}
        >
          VER CAPA
        </span>
      ),
    },
    {
      title: 'PDF',
      field: 'pdf',
      render: (rowData: Boutique) => (
        <span
          onClick={() => handleImage(rowData.pdf)}
          style={{ cursor: 'pointer' }}
        >
          VER PDF
        </span>
      ),
    },
    {
      title: 'CRIADO EM',
      field: 'createdAt',
      render: (rowData: Boutique) => (
        <>
          {format(parseISO(rowData.createdAt.toString()), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          })}
        </>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          <MaterialTable
            title="Boutiques"
            columns={columns}
            data={boutiques || []}
            options={options}
            localization={localization}
            actions={[
              {
                icon: 'delete',
                tooltip: 'DELETAR Boutique',
                iconProps: { color: 'secondary' },
                onClick: (event, rowData) => handleDelete(rowData),
              },
            ]}
          />
          <Button
            bgColor="primary"
            onClick={() => history.push('boutique-create')}
          >
            ADICIONAR
          </Button>
          <Button bgColor="secondary" onClick={() => history.goBack()}>
            VOLTAR
          </Button>
        </S.Container>
      )}
    </>
  );
};

export default BoutiqueScreen;
