import api from '../../api';

import { Show } from 'interfaces/Footer';

class FooterData {
  show(userId: string) {
    return api.get<Show>(`footers/${userId}`);
  }

  patch(data: FormData) {
    return api.patch(`footers`, data);
  }
  delete(id: string) {
    return api.delete(`footers/${id}`);
  }
}

export default new FooterData();
