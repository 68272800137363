import styled, { css } from 'styled-components';

interface ButtonProps {
  size?: 'download' | 'default';
  bgColor?:
    | 'download'
    | 'primary'
    | 'default'
    | 'secondary'
    | 'gigi'
    | 'success'
    | 'disabled';
}

const sizeVariations = {
  download: css`
    height: 35px;
    width: 220px;
    font-size: 16px;
  `,

  default: css`
    height: 35px;
    width: 150px;
    font-size: 16px;
  `,
};

const colorVariations = {
  download: css`
    background: var(--download);
    color: var(--white);
    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,

  primary: css`
    background: var(--primary);
    color: var(--white);
    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,

  secondary: css`
    background: var(--secondary);
    color: var(--white);
    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,

  gigi: css`
    background: var(--gigi);
    color: var(--white);
    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,

  success: css`
    background: var(--success);
    color: var(--white);
    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,

  disabled: css`
    background: var(--secondary);
    color: var(--white);
    cursor: not-allowed;

    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,

  default: css`
    background: var(--main);
    color: var(--white);

    transition: 350ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  `,
};

const Button = styled.button<ButtonProps>`
  transition: background-color 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  padding: 6px;
  font-weight: 700;

  border: 0;
  border-radius: 5px;
  cursor: pointer;

  ${props => sizeVariations[props.size || 'default']}
  ${props => colorVariations[props.bgColor || 'default']}

  &:focus {
    outline: none;
  }
`;
export default Button;
