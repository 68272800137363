import api from 'services/api';
import { Merge } from 'interfaces/Kit/MergeImage.interface';

class MergeImage {
  merge(id: string) {
    return api.get<Merge>(`mergeImageFooters/${id}`);
  }

  show(userId: string, kitId: string) {
    return api.post<{ url: string }>(`mergeImageFooters`, {
      userId,
      kitId,
    });
  }
}

export default new MergeImage();
