import React from 'react';
import Lottie from 'react-lottie';
import nothingFound from 'lotties/nothing-found.json';

import * as S from './styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: nothingFound,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const NothingFound: React.FC = () => {
  return (
    <S.Container>
      <Lottie options={defaultOptions} height={200} width={200} />
      <S.Title>Nada Encontrado</S.Title>
    </S.Container>
  );
};

export default NothingFound;
