import styled from 'styled-components';

const LabelFooter = styled.label`
  transition: background-color 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
  padding: 6px;
  font-weight: 700;
  text-align: center;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  background: var(--download);
  color: var(--white);
  &:hover {
    background: var(--download-hover);
  }

  input[type='file'] {
    display: none;
  }
`;

export default LabelFooter;
