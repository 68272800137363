import api from '../api';
import { Authentication } from '../../interfaces/Auth.interface';

class Auth {
  session({ email, password }: Authentication) {
    return api.post('sessions', {
      email,
      password,
    });
  }
}

export default new Auth();
