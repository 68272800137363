import styled, { css } from 'styled-components';

interface BgProps {
  bgColor: 'anjo' | 'comercial';
}

interface TitleProps {
  title: 'anjo' | 'comercial';
}

const titleVariations = {
  anjo: css`
    color: rgb(19, 107, 49);
    font-size: 22px;
  `,
  comercial: css`
    color: var(--white);
    font-size: 22px;
  `,
};

const bgColorVariations = {
  anjo: css`
    background-color: var(--white);
    border: 2px solid rgb(19, 107, 49);
    &:hover {
      background-color: #ddd;
    }
  `,

  comercial: css`
    background-color: rgb(81, 167, 229);
    border: 2px solid rgb(71, 155, 219);
    &:hover {
      background-color: rgb(71, 155, 219);
    }
  `,
};

export const Container = styled.div<BgProps>`
  height: 53px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  transition: background-color 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${props => bgColorVariations[props.bgColor]};
`;

export const Title = styled.h1<TitleProps>`
  ${props => titleVariations[props.title]}
`;
