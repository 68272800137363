import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { minAmountOfImagesToUplaod } from 'utils/constants/kits';
import { apiKit } from 'services/data';
import { Input, Button, FileLabel } from 'styles';
import * as S from './styles';

const Create: React.FC = () => {
  const { register, handleSubmit, errors } = useForm();
  const { addToast } = useToast();
  const history = useHistory();

  const onSubmit = useCallback(
    async data => {
      const fileData = new FormData();
      fileData.append('kit', data.kit);
      fileData.set('referringMonth', data.referringMonth);
      fileData.set('footerWidth', data.footerWidth);
      fileData.set('footerHeight', data.footerHeight);
      fileData.set('horizontalPosition', data.horizontalPosition);
      fileData.set('verticalPosition', data.verticalPosition);
      const { files } = data;
      if (files.length < minAmountOfImagesToUplaod) {
        return addToast({
          type: 'error',
          title: 'ATENÇÃO',
          description:
            'O NÚMERO DE ARTES NÃO SÃO SUFICIENTES PARA MONTAR UM KIT',
        });
      } else {
        for (let i = 0; i < files.length; i++) {
          fileData.append('files', files[i]);
        }
      }

      try {
        await apiKit.patch(fileData);
        history.goBack();
      } catch (err) {
        console.log(err);

        const { status, message } = err.response.data;
        addToast({
          type: status,
          title: 'ATENÇÃO',
          description: message,
        });
      }
    },
    [addToast, history],
  );
  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.InputContainer>
        <Input
          error={errors.kit}
          name="kit"
          type="text"
          ref={register({ required: true, minLength: 3 })}
          placeholder="Nome do KIT"
        />
        <Input
          error={errors.referringMonth}
          name="referringMonth"
          type="date"
          ref={register({ required: true, minLength: 3 })}
          placeholder="Data referente"
        />
      </S.InputContainer>
      <S.InputContainer>
        <Input
          error={errors.footerWidth}
          name="footerWidth"
          type="text"
          ref={register({ required: true })}
          placeholder="Largura do Rodapé"
        />
        <Input
          error={errors.footerHeight}
          name="footerHeight"
          type="text"
          ref={register({ required: true })}
          placeholder="Altura do Rodapé"
        />
      </S.InputContainer>
      <S.InputContainer>
        <Input
          error={errors.horizontalPosition}
          name="horizontalPosition"
          type="text"
          ref={register({ required: true })}
          placeholder="Posição Horizontal"
        />
        <Input
          error={errors.verticalPosition}
          name="verticalPosition"
          type="text"
          ref={register({ required: true })}
          placeholder="Posição Vertical"
        />
      </S.InputContainer>

      <FileLabel htmlFor="kitFiles">
        ADICIONAR IMAGENS
        <input
          id="kitFiles"
          type="file"
          name="files[]"
          multiple
          ref={register({ required: true })}
        />
      </FileLabel>

      <Button bgColor="primary" type="submit">
        CRIAR
      </Button>
      <Button bgColor="secondary" onClick={() => history.goBack()}>
        VOLTAR
      </Button>
    </S.Container>
  );
};

export default Create;
