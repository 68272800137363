import styled from 'styled-components';

type Props = {
  width?: number;
  mdWidth?: number;
};

const HorizontalStroke = styled.hr<Props>`
  width: ${props => props.width || 27}rem;
  height: 1px;
  border: 1px solid #b12817;
  margin-bottom: 0.625rem;

  @media (max-width: 1000px) {
    width: ${props => props.mdWidth || props.width}rem;
  }
`;

export default HorizontalStroke;
