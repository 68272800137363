import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-top: 5px;
  button {
    margin-right: 20px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin: 1.875rem 0 0.625rem 0;

  @media (max-width: 1000px) {
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Main = styled.main`
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: var(--grey);
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 1rem;

  @media (max-width: 1000px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

export const LastMergeContainer = styled.div`
  margin-top: 1rem;
  width: 80%;
`;
