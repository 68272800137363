import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

export const Logo = styled.img`
  width: 400px;

  @media (max-width: 1000px) {
    width: 200px;
  }
`;
