import api from 'services/api';
import { Kit, AdminKit } from 'interfaces/Kit/Kit.interface';

class Kits {
  show(id: string) {
    return api.get<Kit>(`kits/${id}`);
  }

  all() {
    return api.get<AdminKit[]>('kits');
  }

  delete(id: string) {
    return api.delete(`kits/${id}`);
  }

  patch(data: FormData) {
    return api.patch('kits', data);
  }
}

export default new Kits();
