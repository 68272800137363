import api from '../../api';

import { Create } from 'interfaces/BoutiqueLogger/BoutiqueLogger.interface';

class BoutiqueLoggerData {
  index() {
    return api.get('boutiqueLoggers');
  }

  create(data: Create) {
    return api.post(`boutiqueLoggers`, data);
  }
}

export default new BoutiqueLoggerData();
