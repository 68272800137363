import { useUrl } from 'hooks/url';
import React from 'react';

import * as S from './styles';

type Props = {
  image: string;
};

const Card = ({ image }: Props) => {
  const { urlAgencia } = useUrl();
  return (
    <S.Container>
      <S.Image src={`${urlAgencia}${image}`} />
    </S.Container>
  );
};

export default Card;
