import styled from 'styled-components';

export const Title = styled.h1`
  margin: 1rem;
  font-size: 18px;
  text-align: justify;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.form`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
`;

export const AuthContiner = styled.form`
  height: 350px;
  width: 400px;

  border: 1px solid var(--default-box-shadow);

  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 5px;
`;

export const AuthTitle = styled.h1`
  border-bottom: 1px solid var(--main);
  text-align: center;
  padding: 0.5rem 0;
  color: var(--main);
  /* background: var(--main); */
`;

export const AuthInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 2rem;
  > input {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }
`;

export const ForgotPassword = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  color: var(--secondary);

  &:hover {
    color: var(--black);
  }
`;
