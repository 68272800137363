import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { Button } from 'styles';
import { useUrl } from 'hooks/url';

type Props = {
  image: string;
  text: string;
  path: string;
};

const Card = ({ image, text, path }: Props) => {
  const history = useHistory();
  const { urlAgencia } = useUrl();
  // console.log(urlAgencia, image);
  const handleClick = useCallback(() => {
    history.push(path);
  }, [history, path]);

  return (
    <S.Container>
      <S.Image src={`${urlAgencia}${image}`} />
      <S.Text>{text}</S.Text>
      <Button onClick={handleClick}>VER MAIS</Button>
    </S.Container>
  );
};

export default Card;
