import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;

  button {
    margin: 10px auto;
    width: 100%;
  }
`;
