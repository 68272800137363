import React from 'react';

import * as S from './styles';

interface Props {
  logo: string;
}

const SectionLogo: React.FC<Props> = ({ logo }) => {
  return (
    <S.Container>
      <S.Logo src={logo} />
    </S.Container>
  );
};

export default SectionLogo;
