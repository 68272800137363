import React, { useState, useCallback } from 'react';
import { useAuth } from 'hooks/auth';
import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from 'jwt-decode';
import { ITokenPayload } from 'interfaces/Auth.interface';
import ApiBouterLogger from 'services/data/BoutiqueLogger';
import * as S from './styles';
import { Button } from 'styles';
import { Modal } from 'components';
import { useUrl } from 'hooks/url';

type Props = {
  id: string;
  text: string;
  cover: string;
  pdf: string;
  financialStatus: boolean;
};

const Card = ({ id, text, cover, pdf, financialStatus }: Props) => {
  const [modal, setModal] = useState(false);
  const { user } = useAuth();
  const { keycloak } = useKeycloak();
  const { urlAgencia } = useUrl();
  const handleClick = useCallback(async () => {
    const kcToken = keycloak?.token ?? '';
    const decoded: ITokenPayload = jwt_decode(kcToken);
    const { sub } = decoded;

    await ApiBouterLogger.create({ user_id: sub, boutique_id: id });

    window.open(`${urlAgencia}${pdf}`, '_blank');
    // eslint-disable-next-line
  }, [pdf, user, id]);

  return (
    <S.Container>
      <S.Image src={`${urlAgencia}${cover}`} />
      <S.Text>{text}</S.Text>
      <Button
        onClick={handleClick}
        bgColor={financialStatus ? 'disabled' : 'default'}
        disabled={financialStatus ? true : false}
      >
        Download
      </Button>
      <Modal showModal={modal} closeModal={setModal} />
    </S.Container>
  );
};

export default Card;
