import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px;
  button:nth-child(1) {
    margin-right: 10px;
  }

  button {
    width: auto;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 100%;
      height: 50px;
    }

    button:nth-child(1) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
`;

export const UserContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 800px;
  background: #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 0 10px;

  @media (max-width: 1000px) {
    min-width: 400px;
  }

  input,
  button,
  label {
    width: 100%;
    margin: 0 auto 20px auto;
  }
`;

export const FooterContainer = styled.div`
  height: auto;
  margin: auto;
  padding: 10px;
  background: #b3060b;
  margin-bottom: 20px;
  border-radius: 8px;
`;

export const FooterImg = styled.img`
  @media (max-width: 1000px) {
    width: 350px;
  }
`;

export const InformationText = styled.p`
  text-align: center;
`;

export const InputFooter = styled.input``;

export const WhitoutFooterText = styled.p`
  color: #fff;
`;
