import React, { useState, useEffect } from 'react';
import { Loading, NothingFound, BoutiqueCard, SectionLogo } from 'components';
import { Boutique } from 'interfaces/Boutique/Boutique.interface';
import apiBoutique from 'services/data/Boutique';
import boutiqueEmpatiaLogo from 'assets/images/agencia/boutiqueempatia.svg';
import * as S from './styles';
import { apiFinancialStatus } from 'services/data';

const AllBoutiques: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [boutiques, setBoutiques] = useState<Boutique[]>([]);
  const [financialStatus, setFinancialStatus] = useState(false);

  useEffect(() => {
    apiBoutique.index(true).then(response => setBoutiques(response.data));
    apiFinancialStatus
      .getStatus()
      .then(response => setFinancialStatus(response.data.debito));

    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {boutiques.length > 0 ? (
            <>
              <SectionLogo logo={boutiqueEmpatiaLogo} />
              <S.Main>
                {boutiques &&
                  boutiques.map(boutique => (
                    <BoutiqueCard
                      key={boutique.id}
                      id={boutique.id}
                      cover={boutique.cover}
                      text={boutique.boutique}
                      pdf={boutique.pdf}
                      financialStatus={financialStatus}
                    />
                  ))}
              </S.Main>
            </>
          ) : (
            <NothingFound />
          )}
        </>
      )}
    </>
  );
};

export default AllBoutiques;
