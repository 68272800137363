import api from '../../api';
import {
  Show,
  Update,
  Create,
} from 'interfaces/User';

class UserData {
  show(id: string) {
    return api.get<Show>(`users/${id}`);
  }

  showUsers() {
    return api.get<Show[]>(`users/showUsers`);
  }

  create(data: Create) {
    return api.post<Show>(`users`, data);
  }

  update(data: Update) {
    return api.put(`users`, data);
  }

  delete(id: string) {
    return api.delete(`users/${id}`);
  }

}

export default new UserData();
