import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useKeycloak } from '@react-keycloak/web';

interface AuthContextData {
  isAuthenticated: boolean;
  initialized: boolean;
  meta: {
    keycloak: Keycloak.KeycloakInstance;
  };
  token: string | undefined;
  user: Keycloak.KeycloakProfile;
  roles: Keycloak.KeycloakRoles | undefined;
  login(): void;
  logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const [user, setUser] = useState({});

  // fetch user profile
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userProfile = await keycloak.loadUserProfile();

        setUser({
          ...userProfile,
          fullName: `${userProfile.firstName} ${userProfile.lastName}`,
          id: keycloak.idTokenParsed?.sub,
        });
      } catch (err) {
        console.log({ isVisible: true, message: err.message });
      }
    };

    if (keycloak.authenticated) {
      fetchUserInfo();
    }
  }, [keycloak, initialized]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!keycloak.authenticated,
        initialized,
        meta: {
          keycloak,
        },
        token: keycloak.token,
        user,
        roles: keycloak.realmAccess,
        login: useCallback(() => {
          keycloak.login();
        }, [keycloak]),
        logout: useCallback(() => {
          keycloak.logout();
        }, [keycloak]),
        // register: useCallback(() => {
        //   keycloak.register();
        // }, [keycloak]),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
