import React, { useEffect } from 'react';
import { ToastMessage, useToast } from 'hooks/toast';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';
import * as S from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <S.Container
      type={message.type}
      hasDescription={!!message.description}
      style={style}
    >
      {icons[message.type || 'info']}

      <S.MessageContainer>
        <S.Message>{message.title}</S.Message>
        {message.description && (
          <S.Paragraph>{message.description}</S.Paragraph>
        )}
      </S.MessageContainer>

      <S.Button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </S.Button>
    </S.Container>
  );
};

export default Toast;
