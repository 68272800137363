import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useToast } from 'hooks/toast';
import { Button, Input } from 'styles';

import { FaWhatsapp } from 'react-icons/fa';
import apiUser from 'services/data/User';
import apiFooter from 'services/data/Footer';

import * as S from './styles';
import { whatsappApi } from 'utils/constants';

interface UserParams {
  userId: string;
}

interface User {
  id: string;
  email: string;
  code: number;
  footer?: {
    id: string;
    path: string;
  };
}

export default () => {
  const [user, setUser] = useState<User>({} as User);
  const { addToast } = useToast();

  const { register, handleSubmit, errors, watch, setValue } = useForm();

  const { params } = useRouteMatch<UserParams>();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const response = await apiUser.show(params.userId);

      setValue('email', response.data.email);
      setValue('code', response.data.code);

      const footer = await apiFooter.show(response.data.id);
      setUser({
        id: response.data.id,
        email: response.data.email,
        code: Number(response.data.code),
        footer: { id: footer.data.id, path: footer.data.path },
      });
    }
    fetchData();
    // eslint-disable-next-line
  }, [params.userId]);

  const handleWhatsapp = useCallback(() => {
    window.open(whatsappApi, '_blank');
  }, []);

  const handleUserUpdate = useCallback(
    async data => {
      try {
        data.id = user.id;
        if (data.currentPassword.length === 0) {
          delete data.currentPassword;
          delete data.password;
          delete data.passwordConfirmation;
        }

        await apiUser.update(data);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Cadastro atualizado com sucesso',
        });

        setValue('currentPassword', '');
        setValue('password', '');
        setValue('passwordConfirmation', '');
        history.push('/');
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Algo Aconteceu',
          description: (err as Error).message,
        });
      }
    },
    [addToast, user, setValue, history],
  );

  return (
    <>
      <S.Container>
        <S.UserContainer onSubmit={handleSubmit(handleUserUpdate)}>
          <S.FooterContainer>
            {user.footer?.path ? (
              <S.FooterImg
                src={user.footer.path}
                alt="Rodapé Licenciado Rede Inova Drogarias"
              />
            ) : (
              <S.WhitoutFooterText>Sem Rodapé</S.WhitoutFooterText>
            )}
          </S.FooterContainer>

          <Input
            error={errors.email}
            name="email"
            placeholder="E-mail"
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'E-mail inválido',
              },
            })}
          />

          <Input
            error={errors.currentPassword}
            type="password"
            name="currentPassword"
            placeholder="Senha atual"
            ref={register}
          />
          <Input
            error={errors.password}
            type="password"
            name="password"
            placeholder="Nova senha"
            ref={register({ minLength: 6 })}
          />

          <Input
            error={errors.passowrdConfirmation}
            type="password"
            name="passwordConfirmation"
            placeholder="Confirmar nova senha"
            ref={register({ validate: value => value === watch('password') })}
          />

          <Button type="submit">Salvar</Button>

          <Button
            type="button"
            bgColor="secondary"
            onClick={() => history.goBack()}
          >
            VOLTAR
          </Button>

          <S.InformationText>
            ** Caso seus dados estejam incorretos, favor procurar a Agência de
            Marketing{' '}
            <FaWhatsapp
              size={22}
              onClick={handleWhatsapp}
              style={{ cursor: 'pointer' }}
            />{' '}
            **
          </S.InformationText>
        </S.UserContainer>
      </S.Container>
    </>
  );
};
