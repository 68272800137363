import React, { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { useToast } from 'hooks/toast';
import { Dialog, DialogActions, Slide } from '@material-ui/core';

import { Button, Input } from 'styles';
import * as S from './styles';

import { TransitionProps } from '@material-ui/core/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalProps {
  showModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalView: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  ...rest
}) => {
  // const { signIn } = useAuth();
  // const { addToast } = useToast();
  const history = useHistory();
  
  // eslint-disable-next-line
  const { register, handleSubmit, errors } = useForm();

  // const onSubmit = useCallback(
  //   async data => {
  //     try {
  //       await signIn({
  //         email: data.email,
  //         password: data.password,
  //       });

  //       closeModal(false);
  //     } catch (err) {
  //       const { status, message } = err.response.data;
  //       addToast({
  //         type: status,
  //         title: 'Erro na autenticação',
  //         description: message,
  //       });
  //     }
  //   },
  //   [signIn, addToast, closeModal],
  // );

  const handleClose = () => {
    closeModal(false);
  };

  const handleForgotPassword = useCallback(() => {
    closeModal(false);
    history.push('/forgotPassword');
  }, [history, closeModal]);
  // onSubmit={handleSubmit(onSubmit)}
  return (
    <div>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <S.Content>
          <S.AuthContiner>
            <S.AuthTitle>LOGIN</S.AuthTitle>
            <S.AuthInput>
              <Input
                autoFocus={true}
                error={errors.email}
                type="text"
                name="email"
                placeholder="E-mail"
                ref={register({ required: true, minLength: 3 })}
              />
              <Input
                error={errors.password}
                type="password"
                name="password"
                placeholder="Senha"
                ref={register({ required: true, minLength: 6 })}
              />
              <Button type="submit">ENTRAR</Button>

              <S.ForgotPassword onClick={handleForgotPassword}>
                Esqueci minha senha
              </S.ForgotPassword>
            </S.AuthInput>
          </S.AuthContiner>
        </S.Content>
        <DialogActions>
          <Button
            onClick={handleClose}
            bgColor="secondary"
            style={{ margin: 'auto', width: '100%' }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalView;
