import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--white);
  height: 225px;
  width: 235px;
  border-radius: 12px;
  padding: 20px;
  margin: 1rem 2rem;
  border: 5px solid #dc0000;

  box-shadow: 3px 3px 8px var(--default-box-shadow);
`;

export const Image = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 5px;
`;
