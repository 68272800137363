import api from 'services/api';

class MergedKit {
  deleteByKit(kitId: string): Promise<void> {
    return api.delete(`mergedKits/byKit/${kitId}`);
  }

  deleteByUser(userId: string): Promise<void> {
    return api.delete(`mergedKits/byUser/${userId}`);
  }
}

export default new MergedKit();
