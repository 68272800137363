import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import {
  apiKitSummary,
  apiUser,
  apiBoutique,
  apiFinancialStatus,
} from 'services/data';
import { Summary } from 'interfaces/Kit/Summary.interface';
import { Boutique } from 'interfaces/Boutique/Boutique.interface';

import lancamentosLogo from 'assets/images/agencia/lancamentos.svg';
import maisProcuradosLogo from 'assets/images/agencia/maisprocurados.svg';
import boutique from 'assets/images/agencia/boutique.svg';

import * as S from './styles';
import { Button } from 'styles';
import {
  Card,
  BoutiqueCard,
  NothingFound,
  Loading,
  SectionLogo,
} from 'components';

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState<Summary[]>([]);
  const [boutiques, setBoutiques] = useState<Boutique[]>([]);
  const [mostRelevants, setMostRelevants] = useState<Summary[]>([]);
  const [financialStatus, setFinancialStatus] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    try {
      apiFinancialStatus.getStatus().then(response => {
        setFinancialStatus(response.data.debito);
      });

      apiKitSummary.summary().then(response => setSummary(response.data));

      apiKitSummary
        .mostRelevants()
        .then(response => setMostRelevants(response.data));

      apiBoutique.index(false).then(response => setBoutiques(response.data));

      const data = new Date();

      apiUser.update({ data });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error ao buscar Kits',
        description: (err as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {summary.length === 0 && <NothingFound />}
          {summary.length > 0 && (
            <>
              <SectionLogo logo={lancamentosLogo} />
              <S.ButtonContainer>
                <Button onClick={() => history.push('/allKits')}>
                  Ver mais
                </Button>
              </S.ButtonContainer>
              <S.Main>
                {summary &&
                  summary.map(kit => (
                    <Card
                      key={kit.id}
                      image={kit.path}
                      text={kit.kit}
                      path={`kit/${kit.id}`}
                    />
                  ))}
              </S.Main>

              {mostRelevants.length > 0 && (
                <>
                  <SectionLogo logo={maisProcuradosLogo} />

                  <S.Main>
                    {mostRelevants &&
                      mostRelevants.map(kit => (
                        <Card
                          key={kit.id}
                          image={kit.path}
                          text={kit.kit}
                          path={`kit/${kit.id}`}
                        />
                      ))}
                  </S.Main>
                </>
              )}

              {boutiques.length > 0 && (
                <>
                  <SectionLogo logo={boutique} />
                  <S.ButtonContainer>
                    <Button onClick={() => history.push('/allBoutiques')}>
                      Ver mais
                    </Button>
                  </S.ButtonContainer>
                  <S.Main>
                    {boutiques &&
                      boutiques.map(boutique => (
                        <BoutiqueCard
                          key={boutique.id}
                          id={boutique.id}
                          cover={boutique.cover}
                          text={boutique.boutique}
                          pdf={boutique.pdf}
                          financialStatus={financialStatus}
                        />
                      ))}
                  </S.Main>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Main;
