import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  };

  html, body, #root {
    height: 100%;
    width: 100%;
    background: var(--white);
  }

  @font-face {
    font-family: 'Gotham Black';
    font-weight: 500;
    font-style: bold;
    src: local("Gotham-Black"), url("../assets/fonts/Gotham-Black.ttf") format("truetype");
    src: local("Gotham-Black"), url("../assets/fonts/Gotham-Black.oft") format("opentype");
  }

  *, button, input {
    font-family: 'Gotham Black',Arial,sans-serif;
  }

  :root {
    --main: #DC0000;
    --main-hover: #780000;
    --download: #FF6600;
    --download-hover: #BA2B00;
    --white: #ffffff;
    --black: #000000;
    --grey: #f0f0f0;

    --gigi: #9d5dfa;
    --gigi-hover: #7138ab;

    --primary: #007bff;
    --primary-hover: #0069d9;

    --danger: #dc3545;
    --danger-hover: #c82333;

    --success: #28a745;
    --success-hover: #218838;

    --secondary: #6c757d;
    --secondary-hover: #5a6268;

    --toast-default-bg: #ebf8ff;
    --toast-default-cl: #3172b7

    --bg-error: #f5c6cb;
    --border-error: #a43d3d;
  };


  .container {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;

    @media (max-width: 992px) {
      max-width: 960px;
    }
    @media (max-width: 768px) {
      max-width: 720px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .gigi {
    @media (max-width: 850px) {
      img {
        width: 25vw;
      }
    }
    @media (max-width: 576px) {
      img {
        width: 35vw;
      }
    }
  }
`;
