import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { useToast } from 'hooks/toast';
import { Loading } from 'components';
import { localization, options } from 'utils';
import { Show } from 'interfaces/User/';
// import { apiMergedKit } from 'services/data';
import apiUser from 'services/data/User';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Button } from 'styles';
import * as S from './styles';

const Kit: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [licensees, setLicensees] = useState<Show[]>([]);

  const { addToast } = useToast();
  const history = useHistory();

  async function fetchData() {
    try {
      const response = await apiUser.showUsers();
      setLicensees(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ATENÇÃO',
        description: (err as Error).message,
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // const handleDelete = useCallback(
  //   async ({ id }) => {
  //     try {
  //       if (window.confirm('Tem certeza que deseja deletar este registro?')) {
  //         await apiMergedKit.deleteByUser(id);
  //         await apiUser.delete(id);
  //         await fetchData();
  //       }
  //     } catch (err) {
  //       const { status, message } = err.response.data;
  //       addToast({
  //         type: status,
  //         title: 'ATENÇÃO',
  //         description: message,
  //       });
  //       setIsLoading(false);
  //     }
  //   },
  //   [addToast, fetchData],
  // );

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`/updateUser/${id}`);
    },
    [history],
  );

  const columns = [
    { title: 'CÓDIGO', field: 'code' },
    { title: 'RAZÃO SOCIAL', field: 'company' },
    { title: 'E-MAIL', field: 'email' },
    {
      title: 'CRIADO EM',
      field: 'createdAt',
      render: (rowData: Show) => (
        <>
          {format(new Date(rowData.createdAt), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          })}
        </>
      ),
    },
    {
      title: 'ÚLTIMO LOGIN',
      field: 'lastLoginAt',
      render: (rowData: Show) => (
        <>
          {rowData.lastLoginAt
            ? format(parseISO(rowData.lastLoginAt), 'dd/MM/yyyy HH:mm', {
                locale: ptBR,
              })
            : 'Nunca acessou'}
        </>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          <MaterialTable
            title="Licenciados"
            columns={columns}
            data={licensees || []}
            options={options}
            localization={localization}
            actions={[
              {
                icon: 'visibility',
                tooltip: 'VER LICENCIADO',
                iconProps: { color: 'primary' },
                onClick: (event, rowData) => handleEdit(rowData),
              },
            ]}
          />
          {/* <Button
            bgColor="primary"
            onClick={() => history.push('licensee-create')}
          >
            ADICIONAR
          </Button> */}
          <Button bgColor="secondary" onClick={() => history.goBack()}>
            VOLTAR
          </Button>
        </S.Container>
      )}
    </>
  );
};

export default Kit;
