import api from 'services/api';
import { Summary, LastMerges } from 'interfaces/Kit/Summary.interface';

class KitSummaries {
  allBySummary() {
    return api.get<Summary[]>('kits/allBySummary');
  }
  summary() {
    return api.get<Summary[]>('kits/summary');
  }

  mostRelevants() {
    return api.get<Summary[]>('mostRelevantKits');
  }

  lastMerges() {
    return api.get<LastMerges[]>('mostRelevantKits/all');
  }
}

export default new KitSummaries();
