import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { CSVLink } from 'react-csv';
import { GrDocumentCsv } from 'react-icons/gr';
import { Card, NothingFound } from 'components';
import {
  Summary,
  LastMerges,
  CSVLastMerges,
} from 'interfaces/Kit/Summary.interface';
import {
  BoutiqueLogger,
  CSVBoutiqueLogger,
} from 'interfaces/BoutiqueLogger/BoutiqueLogger.interface';
import { apiKitSummary } from 'services/data';
import apiBoutiqueLogger from 'services/data/BoutiqueLogger';
import { localization, options } from 'utils';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import * as S from './styles';
import { Button, Hr } from 'styles';

const Dashboard: React.FC = () => {
  const [mostRelevants, setMostRelevants] = useState<Summary[]>([]);
  const [lastMerges, setLastMerges] = useState<LastMerges[]>([]);
  const [csvLastMerges, setCSVLastMerges] = useState<CSVLastMerges[]>([]);
  const [boutiqueLoggers, setBoutiqueLoggers] = useState<BoutiqueLogger[]>([]);
  const [csvBoutiqueLoggers, setCSVBoutiqueLoggers] = useState<
    CSVBoutiqueLogger[]
  >([]);

  useEffect(() => {
    try {
      apiKitSummary
        .mostRelevants()
        .then(response => setMostRelevants(response.data));
      apiKitSummary.lastMerges().then(response => {
        setLastMerges(response.data);
        const csv = response.data.map((item: LastMerges) => ({
          codigo: item.userCode,
          email: item.userEmail,
          kit: item.kit,
          acesso: format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          }),
        }));
        setCSVLastMerges(csv);
      });

      apiBoutiqueLogger.index().then(response => {
        setBoutiqueLoggers(response.data);
        const csv = response.data.map((item: BoutiqueLogger) => ({
          codigo: item.user.code,
          boutique: item.boutique.boutique,
          acesso: format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          }),
        }));
        setCSVBoutiqueLoggers(csv);
      });
    } catch (err) {}
  }, []);

  const columns = [
    { title: 'LICENCIADO', field: 'userCode' },
    { title: 'E-MAIL', field: 'userEmail' },
    { title: 'KIT', field: 'kit' },
    {
      title: 'DATA',
      field: 'createdAt',
      render: (rowData: LastMerges) => (
        <>
          {format(parseISO(rowData.createdAt), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          })}
        </>
      ),
    },
  ];

  const boutiqueLoggerColumns = [
    {
      title: 'LICENCIADO',
      field: 'rowData.user.code',
      render: (rowData: BoutiqueLogger) => <>{rowData.user.code}</>,
    },
    {
      title: 'BOUTIQUE',
      field: 'rowData.boutique.boutique',
      render: (rowData: BoutiqueLogger) => <>{rowData.boutique.boutique}</>,
    },
    {
      title: 'DATA',
      field: 'createdAt',
      render: (rowData: BoutiqueLogger) => (
        <>
          {format(parseISO(rowData.createdAt.toString()), 'dd/MM/yyyy HH:mm', {
            locale: ptBR,
          })}
        </>
      ),
    },
  ];

  const history = useHistory();
  return (
    <S.Container>
      <S.ButtonsContainer>
        <Button bgColor="primary" onClick={() => history.push('kit')}>
          KITS
        </Button>
        <Button bgColor="primary" onClick={() => history.push('licensee')}>
          LICENCIADOS
        </Button>
        <Button bgColor="primary" onClick={() => history.push('boutique')}>
          Boutiques
        </Button>
      </S.ButtonsContainer>
      <S.Section>
        <S.Title>MAIS PROCURADOS</S.Title>
        <Hr mdWidth={15} />
      </S.Section>
      {mostRelevants.length === 0 ? (
        <NothingFound />
      ) : (
        <S.Main>
          {mostRelevants &&
            mostRelevants.map(kit => (
              <Card
                key={kit.id}
                image={kit.path}
                text={kit.kit}
                path={`kit/${kit.id}`}
              />
            ))}
        </S.Main>
      )}

      <S.Section>
        <S.Title>KIT - ÚLTIMOS DOWNLOADS</S.Title>
        <Hr mdWidth={15} />
      </S.Section>
      {lastMerges.length === 0 ? (
        <NothingFound />
      ) : (
        <S.LastMergeContainer>
          <CSVLink data={csvLastMerges}>
            <GrDocumentCsv size={30} />
          </CSVLink>
          <MaterialTable
            title=""
            columns={columns}
            data={lastMerges || []}
            options={options}
            localization={localization}
          />
        </S.LastMergeContainer>
      )}

      <S.Section>
        <S.Title>BOUTIQUE - ÚLTIMOS DOWNLOADS</S.Title>
        <Hr mdWidth={15} />
      </S.Section>
      {boutiqueLoggers.length === 0 ? (
        <NothingFound />
      ) : (
        <S.LastMergeContainer>
          <CSVLink data={csvBoutiqueLoggers}>
            <GrDocumentCsv size={30} />
          </CSVLink>
          <MaterialTable
            title=""
            columns={boutiqueLoggerColumns}
            data={boutiqueLoggers || []}
            options={options}
            localization={localization}
          />
        </S.LastMergeContainer>
      )}
    </S.Container>
  );
};

export default Dashboard;
