import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import apiBoutique from 'services/data/Boutique';
import { Input, Button, FileLabel } from 'styles';
import * as S from './styles';

const Create: React.FC = () => {
  const { register, handleSubmit, errors } = useForm();
  const { addToast } = useToast();
  const history = useHistory();

  const onSubmit = useCallback(
    async data => {
      const fileData = new FormData();
      fileData.append('boutique', data.boutique);
      fileData.set('referringMonth', data.referringMonth);
      if (data.files > 2) {
        return addToast({
          type: 'error',
          title: 'ATENÇÃO',
          description: 'O NÚMERO DE ITENS NÃO PODE SER MAIOR DO QUE 02',
        });
      } else {
        for (let i = 0; i < data.files.length; i++) {
          fileData.append('files', data.files[i]);
        }
      }

      try {
        await apiBoutique.patch(fileData);
        history.goBack();
      } catch (err) {
        const { status, message } = err.response.data;
        addToast({
          type: status,
          title: 'ATENÇÃO',
          description: message,
        });
      }
    },
    [addToast, history],
  );
  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.InputContainer>
        <Input
          error={errors.boutique}
          name="boutique"
          type="text"
          ref={register({ required: true, minLength: 3 })}
          placeholder="Nome da Boutique"
        />
        <Input
          error={errors.referringMonth}
          name="referringMonth"
          type="date"
          ref={register({ required: true, minLength: 3 })}
          placeholder="Data referente"
        />
      </S.InputContainer>
      <p style={{ textAlign: 'center' }}>ATENÇÃO, SUBIR 01 PDF E 01 CAPA</p>
      <FileLabel htmlFor="boutiqueFiles">
        ADICIONAR IMAGENS
        <input
          id="boutiqueFiles"
          type="file"
          name="files[]"
          multiple
          ref={register({ required: true })}
        />
      </FileLabel>

      <Button bgColor="primary" type="submit">
        CRIAR
      </Button>
      <Button bgColor="secondary" onClick={() => history.goBack()}>
        VOLTAR
      </Button>
    </S.Container>
  );
};

export default Create;
