import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useToast } from 'hooks/toast';
import { Button, FileLabel } from 'styles';

// import apiUser from 'services/data/User';
import apiFooter from 'services/data/Footer';

import * as S from './styles';
import { useUrl } from 'hooks/url';

interface UserParams {
  userId: string;
}

interface User {
  id: string;
  footer?: {
    id: string;
    path: string;
  };
}

export default () => {
  const [user, setUser] = useState<User>({} as User);
  const { addToast } = useToast();
  const { urlAgencia } = useUrl();

  const { register, handleSubmit } = useForm();

  const { params } = useRouteMatch<UserParams>();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      setUser({ id: params.userId });
      const footer = await apiFooter.show(params.userId);
      setUser({
        id: params.userId,
        footer: { id: footer.data.id, path: footer.data.path },
      });
    }
    fetchData();
  }, [params.userId, history]);

  const handleUserUpdate = useCallback(
    async data => {
      try {
        // data.id = user.id;
        if (user.id && data.file[0]) {
          const fileData = new FormData();
          fileData.append('userId', user.id);
          fileData.append('file', data.file[0]);
          await apiFooter.patch(fileData);
        }
        // delete data.file;
        // await apiUser.update(data);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Cadastro atualizado com sucesso',
        });

        history.push('/');
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Algo Aconteceu',
          description: (err as Error).message,
        });
      }
    },
    // eslint-disable-next-line
    [addToast, user],
  );

  return (
    <>
      <S.Container>
        <S.UserContainer onSubmit={handleSubmit(handleUserUpdate)}>
          <S.FooterContainer>
            {user.footer?.path ? (
              <>
                <input
                  type="hidden"
                  name="id"
                  value={user.footer.id}
                  ref={register}
                />
                <S.FooterImg
                  src={`${urlAgencia}${user.footer.path}`}
                  alt="Rodapé Licenciado Rede Inova Drogarias"
                />
              </>
            ) : (
              <S.WhitoutFooterText>Sem Rodapé</S.WhitoutFooterText>
            )}
          </S.FooterContainer>

          <FileLabel htmlFor="footer">
            Escolher um novo Rodapé
            <S.InputFooter id="footer" type="file" name="file" ref={register} />
          </FileLabel>

          <Button type="submit">Salvar</Button>

          <Button
            type="button"
            bgColor="secondary"
            onClick={() => history.goBack()}
          >
            VOLTAR
          </Button>
        </S.UserContainer>
      </S.Container>
    </>
  );
};
