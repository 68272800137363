import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;

  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

export const Main = styled.main`
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: var(--grey);
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 1rem;

  @media (max-width: 1000px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;
