import React, { useState, useCallback, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { ITokenPayload, IUser } from 'interfaces/Auth.interface';
import { useHistory } from 'react-router-dom';
import { IoIosLogOut } from 'react-icons/io';
import { BsGraphUp } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import { useKeycloak } from '@react-keycloak/web';
import { useToast } from 'hooks/toast';
import { Modal, Loading } from 'components';
import headerImg from 'assets/images/topo.png';

import * as S from './styles';

const Header: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<IUser>();
  const { addToast } = useToast();
  const { keycloak, initialized } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    if (initialized) {
      const kcToken = keycloak?.token ?? '';
      const decoded: ITokenPayload = jwt_decode(kcToken);
      if (decoded.realm_access) {
        const {
          sub,
          realm_access: { roles },
        } = decoded;
        setUser({ id: sub, roles });
        setIsLoading(false);
      } else {
        addToast({
          type: 'info',
          title: 'Perfil',
          description: 'Seu perfil não foi definido, fale com a Rede Inova!',
        });
      }
    }
    // eslint-disable-next-line
  }, [initialized]);

  const handleSignout = useCallback(() => {
    addToast({
      type: 'info',
      title: 'aah :(',
      description: 'Volte sempre!',
    });
    keycloak.logout();
  }, [addToast, keycloak]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          <Modal showModal={showModal} closeModal={setShowModal} />
          <S.ItensContainer>
            <FaHome
              style={{ marginLeft: 0 }}
              size={30}
              onClick={() => history.push('/')}
            />
            <S.AuthItensContainer>
              {(user && user.roles.includes('admin')) ||
              (user && user.roles.includes('employee-admin')) ? (
                <BsGraphUp
                  size={30}
                  onClick={() => history.push('/dashboard')}
                />
              ) : null}
              <IoIosLogOut size={30} onClick={handleSignout} />
            </S.AuthItensContainer>
          </S.ItensContainer>
          <S.Logo
            src={headerImg}
            alt="Logo Rede Inova Drogarias IdHouse"
            onClick={() => history.push('/')}
          />
        </S.Container>
      )}
    </>
  );
};

export default Header;
