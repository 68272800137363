import React, { useState, useEffect } from 'react';
import { useToast } from 'hooks/toast';
import { apiKitSummary } from 'services/data';
import { Summary } from 'interfaces/Kit/Summary.interface';

import todosOsKitsLogo from 'assets/images/agencia/todososkits.svg';
import * as S from './styles';
import { Card, NothingFound, SectionLogo } from 'components';

const AllKits: React.FC = () => {
  const [summary, setSummary] = useState<Summary[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const summaryResponse = await apiKitSummary.allBySummary();

        setSummary(summaryResponse.data);
      } catch (err) {
        const { status, message } = err.response.data;
        addToast({
          type: status,
          title: 'Error ao buscar Kits',
          description: message,
        });
      }
    }
    fetchData();
  }, [addToast]);
  return (
    <>
      {summary && summary.length === 0 ? (
        <NothingFound />
      ) : (
        <>
          <SectionLogo logo={todosOsKitsLogo} />
          <S.Main>
            {summary &&
              summary.map(kit => (
                <Card
                  key={kit.id}
                  image={kit.path}
                  text={kit.kit}
                  path={`kit/${kit.id}`}
                />
              ))}
          </S.Main>
        </>
      )}
    </>
  );
};

export default AllKits;
