import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  button,
  label {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  input {
    width: 49%;
    margin-bottom: 1rem;
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    input {
      width: 100%;
    }
  }
`;
