import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { useToast } from 'hooks/toast';
import { Loading } from 'components';
import { localization, options } from 'utils';
import { AdminKit } from 'interfaces/Kit/Kit.interface';
import { apiKit, apiMergedKit } from 'services/data';

import { Button } from 'styles';
import * as S from './styles';

const Kit: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [kits, setKits] = useState<AdminKit[]>([]);

  const { addToast } = useToast();
  const history = useHistory();

  async function fetchData() {
    try {
      const response = await apiKit.all();
      setKits(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ATENÇÃO',
        description: (err as Error).message,
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleDelete = useCallback(
    async ({ id }) => {
      setIsLoading(true);
      try {
        if (window.confirm('Tem certeza que deseja deletar este registro?')) {
          await apiMergedKit.deleteByKit(id);
          await apiKit.delete(id);
          await fetchData();
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'ATENÇÃO',
          description: (err as Error).message,
        });
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [addToast],
  );

  const columns = [
    { title: 'KIT', field: 'kit' },
    { title: 'LARGURA RODAPÉ', field: 'footerWidth' },
    { title: 'ALTURA RODAPÉ', field: 'footerHeight' },
    {
      title: 'POSIÇÃO HORIZONTAL',
      field: 'horizontalPosition',
    },
    { title: 'POSIÇÃO VERTICAL', field: 'verticalPosition' },
  ];
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          <MaterialTable
            title="Kits"
            columns={columns}
            data={kits || []}
            options={options}
            localization={localization}
            actions={[
              {
                icon: 'delete',
                tooltip: 'DELETAR KIT',
                iconProps: { color: 'secondary' },
                onClick: (event, rowData) => handleDelete(rowData),
              },
            ]}
          />
          <Button bgColor="primary" onClick={() => history.push('kit-create')}>
            ADICIONAR
          </Button>
          <Button bgColor="secondary" onClick={() => history.goBack()}>
            VOLTAR
          </Button>
        </S.Container>
      )}
    </>
  );
};

export default Kit;
