import styled from 'styled-components';

export const Container = styled.header`
  margin: 0;
  background-image: linear-gradient(to right,#ffb245,#e73439, #b31b62);
  height: 60px;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
`;

export const ItensContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;

  svg {
    cursor: pointer;
  }

`;
export const MenuItensContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
`;

export const AuthItensContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;
