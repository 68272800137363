import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
`;

export const LoadingTitle = styled.h1`
  font-size: 1.5rem;
  margin-top: 1.25rem;
  text-align: center;
`;

export const ButtonContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1.25rem;

  #buttons_wrapper {
    display: flex;
    gap: 0.5rem;
  }

  @media (max-width: 62.5rem) {
    svg {
      height: 1.25rem;
    }
    button {
      height: 1.625rem;
      width: auto;
    }
  }
`;

export const CardContainer = styled.main`
  display: grid;
  grid-template-columns: auto auto auto;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (max-width: 62.5rem) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 48rem) {
    grid-template-columns: auto;
  }
`;
