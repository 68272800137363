import api from '../../api';

import { Update } from 'interfaces/Boutique/Boutique.interface';

class BoutiqueData {
  index(allBoutiques: boolean) {
    return api.get(`boutiques?allBoutiques=${allBoutiques}`);
  }

  patch(data: FormData) {
    return api.patch(`boutiques`, data);
  }

  update(data: Update) {
    return api.put(`boutiques/${data.id}`, { data });
  }

  delete(id: string) {
    return api.delete(`boutiques/${id}`);
  }
}

export default new BoutiqueData();
