import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 20px;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
`;

export const ItensContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  svg {
    cursor: pointer;
    margin-left: 20px;
    z-index: 99;
  }

  @media (max-width: 1000px) {
    svg {
      height: 20px;
      margin-left: 5px;
    }
  }
`;

export const AuthItensContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
